import React from 'react'
import "./InfoPage.css"

function TestPage() {
  return (
    <div className='InfoPage'>
        <button>TestInfo</button>

    </div>
  )
}

export default TestPage